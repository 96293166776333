/* global angular */

angular.module('AemExtDocumentsSnippet')
  .component('fileImportant', {
    template: require('./file-important.component.tpl.html'),
    controller: FileImportantCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileImportantCtrl.$inject = ['wgService']

function FileImportantCtrl (wgService) {
  var vm = this

  vm.$onInit = function () {
    vm.goToFileUrl = goToFileUrl
  }

  function goToFileUrl () {
    var context = wgService.getParams().wcmHrefContext
    window.location = context ? context + '?documentId=' + vm.file.objectName : vm.file.objectName
  }
}
