/* global angular */

require('./components/folder-content/folder-content.component.js')

angular.module('AemExtDocumentsSnippet')
  .controller('aemExtDocumentsSnippetCtrl', ctrl)

ctrl.$inject = ['$rootScope', 'adfsTokenService']

function ctrl ($rootScope, adfsTokenService) {
  var vm = this

  vm.$onInit = function () {
    vm.tokenReady = adfsTokenService.getToken()
    vm.errorBackend = null
    $rootScope.$on('$onBackendError', function (e, err) {
      vm.errorBackend = err
    })
  }
}
