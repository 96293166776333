/* global angular */

angular.module('AemExtDocumentsSnippet')
  .component('search', {
    template: require('./search.component.tpl.html'),
    controller: SearchCtrl,
    controllerAs: 'vm',
    bindings: {}
  })

SearchCtrl.$inject = ['wgService', 'textToolsService', 'extTreeFactory', '$httpParamSerializer']

function SearchCtrl (wgService, textToolsService, extTreeFactory, $httpParamSerializer) {
  var vm = this

  vm.$onInit = function () {
    vm.categories = []
    vm.products = []
    vm.keyword = ''
    vm.product = ''
    vm.category = {}
    extTreeFactory.createDataservicePromise.then(function (treeDataservice) {
      vm.categories = treeDataservice.getRootCategoryDict()
      vm.products = treeDataservice.getProductList()
    })
    vm.search = search
    vm.reset = reset
  }

  function search () {
    var context = (wgService.getParams().documentPageUrl || '//www.csas.cz').replace(/\/$/, '')
    var searchParams = {}

    vm.keyword && (searchParams.keyword = vm.keyword)
    vm.category && vm.category.id && (searchParams.category = getCategoryQuery(vm.category))
    vm.product && (searchParams.product = vm.product)

    if (vm.keyword || vm.product || (vm.category && vm.category.id)) {
      window.location = context + '/query?' + $httpParamSerializer(searchParams)
    } else {
      reset()
      window.location = context + '/'
    }
  }

  function reset () {
    vm.keyword = null
    vm.product = null
    vm.category = {}
  }

  function getCategoryQuery (category) {
    var categoryQuery = category.id

    if (category && Object.keys(category).length) {
      var name = textToolsService.removeDiacritics(category.title).replace(/[ /]/g, '-')
      name && (categoryQuery = categoryQuery + '_' + name)
    }

    return categoryQuery
  }
}
