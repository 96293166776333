export const cs = {
  EXT_DOCUMENTS: {
    TITLE: 'Dokumenty',
    CATEGORIES: 'Všechny dokumenty',
    RESULTS: {
      TOTAL_FOUND: 'Celkem nalezen |||| Celkem nalezeny |||| Celkem nalezeno',
      DOCUMENTS: '%{smart_count} dokument |||| %{smart_count} dokumenty |||| %{smart_count} dokumentů',
      FOR_KEYWORD: 'pro hledaný výraz'
    },
    FOLDER: {
      DOCUMENT_COUNT: 'počet dokumentů',
      MORE_INFO: 'Otevřít složku',
      SHOW_MORE: 'Zobrazit další dokumenty'
    },
    FILE: {
      IMPORTANT: {
        SHOW_MORE: 'Více informací'
      }
    },
    SEARCH: {
      SEARCH_DOCUMENT: 'Vyhledejte dokument',
      DOCUMENT_NAME: 'Název dokumentu',
      CHOOSE_CATEGORY: 'Kategorie',
      SELECT_CATEGORY: 'Všechny kategorie',
      CHOOSE_PRODUCT: 'Dokument',
      SELECT_PRODUCT: 'Všechny dokumenty',
      SEARCH_BUTTON: 'Hledat',
      RESET_BUTTON: 'Zrušit filtr'
    }
  }
}
