/* global angular */

var filesize = require('filesize')

angular.module('AemExtDocumentsSnippet')
  .filter('humanFileSize', function () {
    return function (input) {
      if (!input) {
        return input
      }

      return filesize(input, { round: 0 })
    }
  })
