/* global angular */

require('angular-cache')

angular
  .module('ExtDocumentsSrv', ['angular-cache'])
  .factory('extDocumentsService', extDocumentsService)

extDocumentsService.$inject = ['$http', 'apiBase', '$q', 'adfsTokenService', 'CacheFactory']

function extDocumentsService ($http, apiBase, $q, adfsTokenService, CacheFactory) {
  var endpointPath = apiBase + 'v2/cms/extranet'
  CacheFactory('treeCache', {
    maxAge: 30 * 60 * 1000,
    storageMode: 'sessionStorage',
    deleteOnExpire: 'passive'
  })

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  function getTree (rootId, type) {
    var deffered = $q.defer()
    $http({
      url: endpoint('content/' + rootId),
      method: 'GET',
      cache: CacheFactory.get('treeCache'),
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      params: {
        type: type
      }
    }).then(function (res) {
      for (var i = 0, l = res.data.length; i < l; i++) {
        if (res.data[i].parentId === rootId) {
          res.data[i].parentId = 0
        }
      }
      deffered.resolve(res)
    }).catch(function (err) {
      adfsTokenService.handle403Error(err)
      deffered.reject(err)
    })

    return deffered.promise
  }

  return {
    getTree: getTree
  }
}
