/* global angular */

angular.module('AemExtDocumentsSnippet')
  .filter('upperFileExtension', function () {
    return function (fileName) {
      if (!fileName) {
        return fileName
      }

      var exploded = fileName.split('.')

      return exploded.length > 1 ? exploded.reverse()[0].toUpperCase() : ''
    }
  })
