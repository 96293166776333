/* global angular */

angular.module('AemExtDocumentsSnippet')
  .component('fileHtml', {
    template: require('./file-html.component.tpl.html'),
    controller: FileHtmlCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileHtmlCtrl.$inject = ['extContentService']

function FileHtmlCtrl (extContentService) {
  var vm = this

  vm.$onInit = function () {
    vm.fileContent = null
    download(vm.file)
  }

  function download (file) {
    file.isDownloadError = false
    extContentService.getHtml(file.portal, file.objectName).promise
      .then(function (res) {
        vm.fileContent = res.data
      })
      .catch(function () {
        file.isDownloadError = true
      })
  }
}
