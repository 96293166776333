/* global angular, dataLayer */

angular.module('AemExtDocumentsSnippet')
  .component('folder', {
    template: require('./folder.component.tpl.html'),
    controller: FolderCtrl,
    controllerAs: 'vm',
    bindings: {
      node: '<'
    }
  })

FolderCtrl.$inject = ['wgService', 'textToolsService', 'appConfig']

function FolderCtrl (wgService, textToolsService, appConfig) {
  var vm = this

  vm.$onInit = function () {
    vm.goToNodeUrl = goToNodeUrl
    vm.getBackgroundImageStyle = getBackgroundImageStyle
  }

  function goToNodeUrl () {
    gtmData(vm.node.title)
    var name = textToolsService.toHumanReadableUriPart(vm.node.title)
    name = name ? '/' + name : null
    var context = wgService.getParams().documentPageUrl || '//www.csas.cz'
    context = context.replace(/\/$/, '')
    window.location = context + '/' + vm.node.id + name
  }

  function getBackgroundImageStyle () {
    return vm.node.icoUrl && {
      'background-image': 'url("' + appConfig.extP24.folderBackgroundImageDomain + vm.node.icoUrl + '")'
    }
  }

  function gtmData (title) {
    var data = {
      event: 'product_main-menu_click',
      sectionName: title
    }
    dataLayer.push(data)
  }
}
