/* global angular */

require('../file-image/file-image.component.js')
require('../file-html/file-html.component.js')

angular.module('AemExtDocumentsSnippet')
  .component('filesContent', {
    template: require('./files-content.component.tpl.html'),
    controller: FilesContentCtrl,
    controllerAs: 'vm',
    bindings: {
      fileList: '<'
    }
  })

FilesContentCtrl.$inject = []

function FilesContentCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.isImage = isImage
    vm.isHtml = isHtml
  }

  function isImage (file) {
    var supportedExtensions = ['jpg', 'png', 'gif']
    var extension = file.objectName.split('.').reverse()[0]
    return supportedExtensions.some(isInList.bind(this, extension))
  }

  function isHtml (file) {
    var supportedExtensions = ['html', 'htm', 'xml']
    var extension = file.objectName.split('.').reverse()[0]
    return supportedExtensions.some(isInList.bind(this, extension))
  }

  function isInList (extension, element) {
    return element === extension
  }
}
