/* global angular */

var textToolsService = require('./text-tools.service.js').textToolsService

angular.module('ExtTreeDataservice', [])
  .factory('extTreeDataservice', extTreeDataservice)
  .factory('textToolsService', textToolsService)

extTreeDataservice.$inject = ['textToolsService']

function extTreeDataservice (textToolsService) {
  var nodeList = []
  var nodeListLookup = {}
  var self = {
    setNodeList: setNodeList,
    decomposeNodeByParentId: decomposeNodeByParentId,
    getFullPathToNodeId: getFullPathToNodeId,
    getNodeById: getNodeById,
    getCategoryDict: getCategoryDict,
    getRootCategoryDict: getRootCategoryDict,
    getProductList: getProductList,
    findFilesByNameAndCategoryIdAndProduct: findFilesByNameAndCategoryIdAndProduct
  }

  function setNodeList (list) {
    nodeList = list
    nodeList.forEach(function (node) {
      nodeListLookup[node.id] = node
    })
    nodeList.forEach(function (node) {
      if (node.parentId && nodeListLookup[node.parentId]) {
        if (!nodeListLookup[node.parentId].nodes) {
          nodeListLookup[node.parentId].nodes = []
        }
        nodeListLookup[node.parentId].nodes.push(node)
      }
    })
  }

  function decomposeNodeByParentId (parentId) {
    var folders = []
    var files = []
    for (var i = 0, len = nodeList.length; i < len; i++) {
      if (nodeList[i].parentId === parentId) {
        folders.push(nodeList[i])
      }
      if (nodeList[i].id === parentId) {
        files = nodeList[i].files
      }
    }

    folders.forEach(function (folder) {
      folder.subtreeFileCount = getSubTreeNodeList(folder.id, []).reduce(function (acc, cur) {
        return acc + (cur.files ? cur.files.length : 0)
      }, 0)
    })

    return {
      folders: folders,
      files: files
    }
  }

  function getFullPathToNodeId (nodeId) {
    var path = []
    while (nodeId) {
      path.push(nodeListLookup[nodeId])
      nodeId = nodeListLookup[nodeId].parentId
    }
    return path.reverse()
  }

  function getNodeById (nodeId) {
    return nodeListLookup[nodeId]
  }

  function getCategoryDict () {
    return nodeList.map(function (item) {
      return { id: item.id, title: item.title }
    })
  }

  function getRootCategoryDict () {
    return nodeList
      .reduce(function (acc, cur) {
        if (cur.parentId === 0) {
          acc.push({ id: cur.id, title: cur.title })
        }
        return acc
      }, [])
      .sort(function (a, b) {
        var titleA = a.title.toLowerCase()
        var titleB = b.title.toLowerCase()
        if (titleA < titleB) {
          return -1
        }
        if (titleA > titleB) {
          return 1
        }

        return 0
      })
  }

  function getProductList () {
    var productList = []
    nodeList.forEach(function (node) {
      node.files && node.files.forEach(function (file) {
        file.products && (productList = productList.concat(file.products))
      })
    })
    // unique
    productList = productList.filter(function (element, index, array) {
      return array.indexOf(element) === index
    })
    return productList
  }

  function findFilesByNameAndCategoryIdAndProduct (substring, categoryId, product) {
    var files = []
    var nodes = getSubTreeNodeList(categoryId, [])

    if (!nodes.length) {
      return []
    }

    nodes.forEach(function (node) {
      if (node.files) {
        files = files.concat(node.files.map(function (file) {
          file.parentNodeId = node.id
          return file
        }))
      }
    })

    // filter by file name or tag
    if (substring) {
      files = files.filter(function (element, index, array) {
        return (element.title && indexOfWithoutDiacriticsCaseInsensitive(element.title, substring) > -1) ||
          (element.tag && indexOfWithoutDiacriticsCaseInsensitive(element.tag, substring) > -1)
      })
    }

    // filter by product
    if (product) {
      files = files.filter(function (element, index, array) {
        return element.products.indexOf(product) > -1
      })
    }

    return files
  }

  function getSubTreeNodeList (parentId, nodeSubList) {
    var localParentId = parentId || 0
    if (!localParentId) {
      return nodeList
    } else {
      nodeSubList.push(nodeListLookup[parentId])
      var nodes = nodeListLookup[parentId].nodes
      if (nodes) {
        nodes.forEach(function (node) {
          getSubTreeNodeList(node.id, nodeSubList)
        })
      }
      return nodeSubList
    }
  }

  function indexOfWithoutDiacriticsCaseInsensitive (string, substring) {
    return textToolsService.removeDiacritics(string.toLowerCase()).indexOf(textToolsService.removeDiacritics(substring.toLowerCase()))
  }

  return self
}
