/* global angular */

angular.module('AemExtDocumentsSnippet')
  .component('fileImage', {
    template: require('./file-image.component.tpl.html'),
    controller: FileImageCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileImageCtrl.$inject = ['extContentService']

function FileImageCtrl (extContentService) {
  var vm = this

  vm.$onInit = function () {
    vm.file.isDownloadError = false
    vm.file.blobUrl || extContentService.getBlobUrl(vm.file.portal, vm.file.objectName).promise
      .then(function (res) {
        vm.file.blobUrl = res.data
      })
      .catch(function () {
        vm.file.isDownloadError = true
      })
  }
}
