/* global angular */

require('./ext-tree.dataservice.js')

angular.module('ExtTreeFactory', ['ExtTreeDataservice'])
  .factory('extTreeFactory', extTreeFactory)

extTreeFactory.$inject = ['$rootScope', 'appLoader', 'extDocumentsService', 'extTreeDataservice', 'wgService', '$q']

function extTreeFactory ($rootScope, appLoader, extDocumentsService, extTreeDataservice, wgService, $q) {
  var rootId = 13539
  var type = 'MSF'
  var self = {
    createDataservicePromise: createDataservicePromise()
  }

  function createDataservicePromise () {
    var deffered = $q.defer()
    appLoader.show()
    rootId = parseInt(wgService.getParams().rootId) || 13539
    type = wgService.getParams().type || 'MSF'
    extDocumentsService.getTree(rootId, type)
      .then(function (res) {
        extTreeDataservice.setNodeList(res.data)
        deffered.resolve(extTreeDataservice)
      })
      .catch(function () {
        $rootScope.$emit('$onBackendError', {
          code: 'ERR_GENERAL'
        })
        deffered.reject()
      })
      .finally(function () {
        appLoader.hide()
      })

    return deffered.promise
  }

  return self
}
