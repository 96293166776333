/* global angular */

require('../folder/folder.component.js')
require('../files/files.component.js')
require('../files-content/files-content.component.js')
require('../file-important/file-important.component.js')
require('../search/search.component.js')

angular.module('AemExtDocumentsSnippet')
  .component('folderContent', {
    template: require('./folder-content.component.tpl.html'),
    controller: FolderContentCtrl,
    controllerAs: 'vm',
    bindings: {}
  })

FolderContentCtrl.$inject = ['extTreeFactory']

function FolderContentCtrl (extTreeFactory) {
  var vm = this

  vm.$onInit = function () {
    vm.folder = []
    vm.filesTop = []
    vm.filesContent = []
    vm.filesImportant = []
    vm.files = []
    vm.resolved = false
    extTreeFactory.createDataservicePromise
      .then(function (treeDataservice) {
        var decomposition = treeDataservice.decomposeNodeByParentId(0)
        vm.folders = decomposition.folders
        vm.filesTop = new Files(decomposition.files).getInterpretableFiles().getNotImportantFiles().getTopFiles().toArray()
        vm.filesContent = new Files(decomposition.files).getInterpretableFiles().getNotImportantFiles().getNotTopFiles().toArray()
        vm.filesImportant = new Files(decomposition.files).getImportantFiles().getNotTopFiles().toArray()
        vm.files = new Files(decomposition.files).getNotInterpretableFiles().getNotImportantFiles().getNotTopFiles().toArray()
      })
      .finally(function () {
        vm.resolved = true
      })
  }

  function Files (files) {
    this.__files = files
    this.__intepretedExtensions = ['jpg', 'png', 'gif', 'html', 'htm', 'xml']
    this.__important = 'important'
    this.__top = 'top'

    this.toArray = function () {
      return this.__files
    }

    this.getInterpretableFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        var extension = file.objectName.split('.').reverse()[0]
        return self.__intepretedExtensions.some(self.__isInList.bind(this, extension))
      })
      return new Files(files)
    }

    this.getNotInterpretableFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        var extension = file.objectName.split('.').reverse()[0]
        return !self.__intepretedExtensions.some(self.__isInList.bind(this, extension))
      })
      return new Files(files)
    }

    this.getTopFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        return file.docTypes.some(self.__isInList.bind(this, self.__top))
      })
      return new Files(files)
    }

    this.getNotTopFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        return !file.docTypes.some(self.__isInList.bind(this, self.__top))
      })
      return new Files(files)
    }

    this.getImportantFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        return file.docTypes.some(self.__isInList.bind(this, self.__important))
      })
      return new Files(files)
    }

    this.getNotImportantFiles = function () {
      var self = this
      var files = this.__files.filter(function (file) {
        return !file.docTypes.some(self.__isInList.bind(this, self.__important))
      })
      return new Files(files)
    }

    this.__isInList = function (extension, element) {
      return element === extension
    }
  }
}
