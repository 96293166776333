/* global angular */

require('../../filters/human-file-size.filter.js')
require('../../filters/upper-file-extension.filter.js')

angular.module('AemExtDocumentsSnippet')
  .component('files', {
    template: require('./files.component.tpl.html'),
    controller: FilesCtrl,
    controllerAs: 'vm',
    bindings: {
      fileList: '<'
    }
  })

FilesCtrl.$inject = ['adfsTokenService', 'appConfig', 'wgService']

function FilesCtrl (adfsTokenService, appConfig, wgService) {
  var vm = this
  var token = adfsTokenService.getBase64Token()

  vm.$onInit = function () {
    vm.limit = 10
  }

  vm.$onChanges = function (changesObj) {
    if (changesObj.fileList) {
      vm.fileList = vm.fileList && vm.fileList.map(addExtraAttributes(appConfig.extP24.fileDownloadUrlPrefix))
    }
  }

  function addExtraAttributes (fileContext) {
    return function (file) {
      file.fullUrl = fileContext + file.objectName + '?link=' + token
      file.extension = getFileExtension(file.objectName)
      if (file.extension.toLowerCase() === 'xml' && file.url) {
        file.fullUrl = fileContext + file.url + '?link=' + token
        file.extension = 'pdf'
      } else if (file.extension.toLowerCase() === 'xml' && !file.url) {
        var context = wgService.getParams().wcmHrefContext
        file.fullUrl = context ? context + '?documentId=' + file.objectName : file.objectName
      }
      return file
    }
  }

  function getFileExtension (fileName) {
    if (!fileName) {
      return fileName
    }
    var exploded = fileName.split('.')
    return exploded.length > 1 ? exploded.reverse()[0] : ''
  }
}
