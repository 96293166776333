export const en = {
  EXT_DOCUMENTS: {
    TITLE: 'Documents',
    CATEGORIES: 'All documents',
    RESULTS: {
      TOTAL_FOUND: 'Total found |||| Total found',
      DOCUMENTS: '%{smart_count} document |||| %{smart_count} documents',
      FOR_KEYWORD: 'for the searched term'
    },
    FOLDER: {
      DOCUMENT_COUNT: 'number of documents',
      MORE_INFO: 'Open file',
      SHOW_MORE: 'Show more documents'
    },
    FILE: {
      IMPORTANT: {
        SHOW_MORE: 'Show more'
      }
    },
    SEARCH: {
      SEARCH_DOCUMENT: 'Search for a document',
      DOCUMENT_NAME: 'Document name',
      CHOOSE_CATEGORY: 'Category',
      SELECT_CATEGORY: 'All categories',
      CHOOSE_PRODUCT: 'Document',
      SELECT_PRODUCT: 'All documents',
      SEARCH_BUTTON: 'Search',
      RESET_BUTTON: 'Reset'
    }
  }
}
